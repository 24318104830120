(function () {
	'use strict';

	angular
		.module('app')
		.directive('svelteComponent', [
			'$rootScope',
			'$translate',
			'firebaseIO',
			'seedcodeCalendar',
			'dataStore',
			'utilities',
			'environment',
			'manageCalendarActions',
			svelteComponent,
		])
		.directive('calendar', ['environment', calendar])
		.directive('viewLoadingIndicator', viewLoadingIndicator)
		.directive('appLoadingIndicator', [
			'$rootScope',
			'$location',
			appLoadingIndicator,
		])
		.directive('appLoadingImage', appLoadingImage)
		.directive('appLoadingFooter', appLoadingFooter)
		.directive('routeLoadingIndicator', [
			'$rootScope',
			'$route',
			'environment',
			routeLoadingIndicator,
		])
		.directive('onFinishRepeat', onFinishRepeat)
		.directive('customScroll', [
			'$rootScope',
			'$window',
			'$timeout',
			customScroll,
		])
		.directive('clamp', ['$timeout', clamp])
		.directive('distanceArrow', distanceArrow)
		.directive('distanceWithLabel', distanceWithLabel)
		.directive('iosNoBounce', iosNoBounce)
		.directive('resize', [
			'$window',
			'$rootScope',
			'$timeout',
			'utilities',
			resize,
		])
		.directive('banner', [
			'$rootScope',
			'$translate',
			'seedcodeCalendar',
			banner,
		]);

	function svelteComponent(
		$rootScope,
		$translate,
		firebaseIO,
		seedcodeCalendar,
		dataStore,
		utilities,
		environment,
		manageCalendarActions
	) {
		const sharedProps = {
			config: window._CONFIG,
			environment: environment,
			firebaseIO: firebaseIO,
			seedcodeCalendar: seedcodeCalendar,
			scrollToY: utilities.scrollToY,
			generateUID: utilities.generateUID,
			performFileMakerScript: utilities.performFileMakerScript,
			getDBKPlatform: utilities.getDBKPlatform,
			dataStore: dataStore,
			closePopovers: function () {
				$rootScope.$broadcast('closePopovers');
			},
			/**
			 * @param {Array<string>} translationTerms - An array of translation keys
			 * @param {Object} [translationValues] - an object containing key value pairs of translation values to inject
			 */
			getTranslations: function (translationTerms, translationValues) {
				const translations = $translate.instant(
					translationTerms,
					translationValues
				);
				return translations;
			},
			manageCalendarActions: manageCalendarActions,
		};
		return {
			restrict: 'EA',
			scope: {
				props: '=',
			},
			link: function (scope, element, attrs) {
				const component = attrs.component;
				const targetElement = element[0];
				const id = utilities.generateUniquePublicID();

				let props = {
					componentProps: scope.props,
					sharedProps: sharedProps,
				};

				globalThis.svelteConnector.showComponent(
					id,
					component,
					targetElement,
					props
				);

				scope.$on('reload-unscheduled', function () {
					// Refresh component
					globalThis.svelteConnector.destroyComponent(id);
					globalThis.svelteConnector.showComponent(
						id,
						component,
						targetElement,
						props
					);
					$.fullCalendar.reportSchedulesLoaded(); // Report to fullcalendar that schedules have loaded
				});

				scope.$on('$destroy', function () {
					globalThis.svelteConnector.destroyComponent(id);
				});
			},
		};
	}

	function calendar(environment) {
		return {
			restrict: 'E',
			link: function (scope, element, attrs) {
				// Just passing template for now but could add scope functions here
			},
			templateUrl: environment.isPhone
				? 'app/mobile/calendar-content.html'
				: 'app/calendar-content.html',
		};
	}

	//Directive to show loading animation during route changes / loading
	function viewLoadingIndicator() {
		return {
			restrict: 'E',
			scope: {
				loading: '=',
			},
			template:
				'<div ng-if="loading" class="view-loader">' +
				'<div class="horizontal-progress"></div>' +
				'</div>',
			link: function (scope, elem, attrs) {
				return;
			},
		};
	}

	//Directive to show loading animation during route changes / loading
	function appLoadingIndicator($rootScope, $location) {
		return {
			restrict: 'E',
			transclude: true,
			scope: {
				loading: '=',
			},
			template: '<div ng-if="enable && loading" ng-transclude></div>',
			link: function (scope, elem, attrs) {
				const path = $location.path();
				scope.enable =
					!path ||
					path === '/' ||
					path.startsWith('/settings') ||
					path.startsWith('/beta');
				$rootScope.$on(
					'$routeChangeStart',
					function (e, next, current) {
						if (current && current.redirectTo !== '/') {
							scope.enable = false;
						}
					}
				);
				return;
			},
		};
	}

	function appLoadingImage(environment) {
		return {
			restrict: 'E',
			template: environment?.urlParms?.noLoader
				? ''
				: '<div class="app-loader-image"><div class="loader-image-pre"></div>\n<div class="loader-image-post fade-in-image"></div></div>',
			link: function (scope, elem, attrs) {
				return;
			},
		};
	}

	function appLoadingFooter() {
		return {
			restrict: 'E',
			template:
				'<div ng-if="isOnline" class="spinner spinner-dark">' +
				'<div class="bounce1"></div>' +
				'<div class="bounce2"></div>' +
				'<div class="bounce3"></div>' +
				'</div>' +
				'<div ng-if="!isOnline" class="message">' +
				'<p>Your device is currently offline.<br />' +
				'DayBack will load once connectivity is restored or you may try again.</p>' +
				'<button ng-click="reload()">Try again</button>' +
				'</div>',
			link: function (scope, elem, attrs) {
				scope.isOnline = window.navigator.onLine;
				scope.reload = function () {
					scope.isOnline = true;
					const messageElement = elem[0].querySelector('.message');
					messageElement.style.display = 'none';
					window.location.reload();
				};
				return;
			},
		};
	}

	//Directive to show loading animation during route changes / loading
	function routeLoadingIndicator($rootScope, $route) {
		return {
			restrict: 'E',
			template:
				'<div ng-if="isRouteLoading" class="view-loader">' +
				'<div class="spinner spinner-dark"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>' +
				'</div>',
			link: function (scope, elem, attrs) {
				scope.isRouteLoading = false;

				$rootScope.$on('$routeChangeStart', function () {
					scope.isRouteLoading = true;
				});

				$rootScope.$on('$routeChangeSuccess', function () {
					scope.isRouteLoading = false;
				});
			},
		};
	}

	function onFinishRepeat() {
		//Used to detect if an ng-repeat is done rendering and emit the event
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if (scope.$last === true) {
					scope.$evalAsync(function () {
						if (attrs.notifyParent) {
							scope.$parent.$emit(attrs.onFinishRepeat);
						} else {
							scope.$emit(attrs.onFinishRepeat);
						}
					});
				}
			},
		};
	}

	function customScroll($rootScope, $window, $timeout) {
		return {
			restrict: 'EA',
			scope: false,
			link: function (scope, element, attrs) {
				//Initialize the scroll
				if (attrs.delay) {
					$timeout(function () {
						initScrollArea();
					}, Number(attrs.delay));
				} else {
					initScrollArea();
				}

				scope.$on('addScroll', function () {
					$timeout(function () {
						initScrollArea();
					}, 0);
				});

				if (attrs.listLength) {
					attrs.$observe('listLength', function () {
						$timeout(function () {
							initScrollArea();
						}, 0);
					});
				}

				angular.element($window).on('resize', initScrollArea);
				angular
					.element($window)
					.on('orientationchange', initScrollArea);

				element.on('$destroy', function () {
					//Remove nanoScroller event bindings
					element.nanoScroller({stop: true});
					angular.element($window).off('resize', initScrollArea);
					angular
						.element($window)
						.off('orientationchange', initScrollArea);
				});

				function initScrollArea() {
					element.nanoScroller();
				}
			},
		};
	}

	function clamp($timeout) {
		return {
			restrict: 'EA',
			scope: false,
			link: function (scope, element, attrs) {
				var container = angular.element(element);
				var content = container.find('.clamp-content');
				var maxHeight = attrs.clampHeight
					? Number(attrs.clampHeight)
					: null;
				var adjusting;

				scope.$watch(
					function () {
						return (
							container.width() +
							container.height() +
							content.text()
						);
					},
					function (newValue, oldValue) {
						if (!adjusting) {
							$timeout(function () {
								adjusting = true;
								var divh = element.height();
								var targetHeight = maxHeight ? maxHeight : divh;
								while (content.outerHeight() > targetHeight) {
									content.text(function (index, text) {
										return text.replace(
											/\W*\s(\S)*$/,
											'...'
										);
									});
								}
								//Allow function to run again once we are done with our loop
								adjusting = false;
							}, 0);
						}
					}
				);
			},
		};
	}

	function distanceArrow() {
		return {
			restrict: 'EA',
			scope: true,
			// replace: true,
			link: function (scope, element, attrs) {
				var color = attrs.color ? attrs.color : element.css('color');
				var hideArrow = attrs.hideArrow && attrs.hideArrow === 'true';
				var textAlign = attrs.textAlign;
				var textPaddingSide = textAlign === 'right' ? 'left' : 'right';
				var textPaddingProperty = !hideArrow
					? 'padding-' + textPaddingSide
					: '';

				scope.containerStyle = {
					position: 'relative',
					width: '100%',
					'margin-top': '1px',
				};
				scope.arrowContainerRightStyle = {
					display:
						hideArrow && textAlign === 'right'
							? 'none'
							: 'inline-block',
					float: 'right',
					'margin-top': '1px',
				};

				scope.arrowContainerLeftStyle = {
					display:
						hideArrow && textAlign === 'left'
							? 'none'
							: 'inline-block',
					float: 'left',
					'margin-top': '1px',
				};

				scope.stopLeftStyle = {
					display: 'inline-block',
					height: '12px',
					'border-left': '1px solid ' + color,
				};

				scope.stopRightStyle = {
					display: 'inline-block',
					height: '12px',
					'border-right': '1px solid ' + color,
				};

				scope.arrowLeftStyle = {
					display: 'inline-block',
					'margin-bottom': '2px',
					width: '0',
					height: '0',
					'border-top': '4px solid transparent',
					'border-bottom': '4px solid transparent',
					'border-right': '14px solid ' + color,
				};

				scope.arrowRightStyle = {
					display: 'inline-block',
					'margin-bottom': '2px',
					width: '0',
					height: '0',
					'border-top': '4px solid transparent',
					'border-bottom': '4px solid transparent',
					'border-left': '14px solid ' + color,
				};

				scope.lineStyle = {
					position: 'absolute',
					height: '1px',
					'border-bottom': '1px solid ' + color,
					top: '5px',
					left: '4px',
					right: '4px',
				};

				scope.tableLineStyle = {
					width: '99%',
				};
				scope.tableLabelStyle = {
					'white-space': 'nowrap',
					color: color,
				};
				scope.tableLabelStyle[textPaddingProperty] = textPaddingProperty
					? '4px'
					: '';
			},
			template: function (tElement, tAttributes) {
				var distanceLine =
					'<div ng-style="containerStyle">\n' +
					' <div ng-style="arrowContainerLeftStyle"><div ng-style="stopLeftStyle"></div><div ng-style="arrowLeftStyle"></div></div>\n' +
					'   <div ng-style="lineStyle"></div>\n' +
					' <div ng-style="arrowContainerRightStyle"><div ng-style="arrowRightStyle"></div><div ng-style="stopRightStyle"></div></div>\n' +
					'</div>\n' +
					'';

				var skeletonLeft =
					'<table>\n' +
					'<tr>\n' +
					'<td ng-style="tableLabelStyle">' +
					tElement.html() +
					'</td>\n' +
					'<td ng-style="tableLineStyle">' +
					distanceLine +
					'</td>\n' +
					'</tr>\n' +
					'</table>\n' +
					'';

				var skeletonRight =
					'<table>\n' +
					'<tr>\n' +
					'<td ng-style="tableLineStyle">' +
					distanceLine +
					'</td>\n' +
					'<td ng-style="tableLabelStyle">' +
					tElement.html() +
					'</td>\n' +
					'</tr>\n' +
					'</table>\n' +
					'';

				return tAttributes.textAlign === 'left'
					? skeletonLeft
					: skeletonRight;
			},
		};
	}

	function distanceWithLabel() {
		return {
			restrict: 'EA',
			scope: {},
			// replace: true,
			link: function (scope, element, attrs) {
				//logic goes here
			},
			template:
				'<table>\n' +
				' <tr>\n' +
				'   <td>\n' +
				' </td>\n' +
				'<td>\n' +
				'<div distance-arrow color="blue"></div>\n' +
				'</td>\n' +
				'</tr>\n' +
				'</table>\n' +
				'',
		};
	}

	function iosNoBounce() {
		return {
			restrict: 'A',
			scope: {},
			link: function (scope, element, attrs) {
				//Prevent document body scroll bounce on iOS when scrolling all items with the provided class
				var target = '.touch-scroll';

				// Variables to track inputs
				var startY, startTopScroll;

				element.on('touchmove', target, touchMove);
				element.on('touchstart', target, touchStart);

				//Clean up external events on element destroy
				scope.$on('$destroy', function () {
					element.off('touchmove', touchMove);
					element.off('touchstart', touchStart);
				});

				function touchMove(e) {
					startTopScroll = this.scrollTop;
					if (startTopScroll !== 0) {
						//e.stopImmediatePropagation(); //ToDo: this interfeered with drag and drop inside scroll areas. I don't see why we need it so it's disabled and should be removed if not necessary
					}
				}

				function touchStart(e) {
					startY = e.originalEvent.touches[0].pageY;
					startTopScroll = this.scrollTop;

					if (startTopScroll <= 0) {
						this.scrollTop = 1;
					}

					if (
						startTopScroll + this.offsetHeight >=
						this.scrollHeight
					) {
						this.scrollTop =
							this.scrollHeight - this.offsetHeight - 1;
					}
				}
			},
		};
	}

	function resize($window, $rootScope, $timeout, utilities) {
		return function (scope, element, attributes) {
			var resize;

			// On window resize => resize the app
			angular.element($window).on('resize', windowResize);
			angular.element($window).on('orientationchange', orientationChange);

			//Clean up external events on element destroy
			scope.$on('$destroy', function () {
				angular.element($window).off('resize', windowResize);
				angular
					.element($window)
					.off('orientationchange', orientationChange);
			});

			function windowResize(e) {
				//Compare this to event target to make sure this isn't an event that has bubbled up
				if (this == e.target) {
					$rootScope.$broadcast('resize');
					$timeout.cancel(resize);
					resize = $timeout(function () {
						utilities.resizeCalendar(0);
					}, 200);
				}
			}

			function orientationChange(e) {
				$rootScope.$broadcast('resize');

				// Scroll if we have rotated back to portrait so iOS address bar appears in safari. Fixes blank space at bottom of page
				window.setTimeout(function () {
					if (window.innerWidth < window.innerHeight) {
						document.body.scrollTop = 0;
					}
				}, 400);
				utilities.resizeCalendar(150);
			}
		};
	}

	function banner($rootScope, $translate, seedcodeCalendar) {
		const registeredMethods = new Map();
		return {
			restrict: 'EA',
			scope: {
				appNotice: '=bind',
			},
			controller: function ($scope) {
				$scope.props = {
					notices: $scope.appNotices,
					registerMethod: registerMethod,
					// content: $scope.content,
				};
				function registerMethod(method, func) {
					// Registered methods are:
					// updateBanner
					registeredMethods.set(method, func);
				}
			},
			link: function (scope, element, attributes) {
				scope.appNotices = new Map();
				const settings = seedcodeCalendar.get('settings');

				// If there is a service end date specified set values to display warning banner
				// End date is set in firebase group settings as serviceEndDate and the value must be a ISO date string
				// "2022-05-15" for example
				if (settings && settings.serviceEndDate) {
					let endDate = moment(settings.serviceEndDate);
					updateShutOffDate(endDate);
				}

				// Draft settings
				const draftSettingsMode =
					seedcodeCalendar.get('draftSettingsMode');
				updateAppNotice(
					'draftSettingsMode',
					!!draftSettingsMode,
					$translate.instant('Draft Settings Mode')
				);
				$rootScope.$on('draftSettingsMode', function (event, data) {
					updateAppNotice(
						'draftSettingsMode',
						!!data,
						$translate.instant('Draft Settings Mode')
					);
				});

				// Beta mode
				const betaMode = seedcodeCalendar.get('betaMode');
				updateAppNotice(
					'betaMode',
					!!betaMode,
					$translate.instant('Beta Mode')
				);
				$rootScope.$on('betaMode', function (event, data) {
					updateAppNotice(
						'betaMode',
						!!data,
						$translate.instant('Beta Mode')
					);
				});

				function updateShutOffDate(shutOffDate) {
					const now = moment();
					let shutOffDays = shutOffDate.isSame(now, 'day')
						? 0
						: Math.max(0, shutOffDate.diff(now, 'days') + 1); // Add one to make exclusive

					let message =
						shutOffDays === 1
							? $translate.instant(
									'Service will end day unless payment is receive',
									{days: shutOffDays}
								)
							: $translate.instant(
									'Service will end days unless payment is received',
									{days: shutOffDays}
								);

					updateAppNotice('shutOffDate', true, message);

					if (shutOffDays > 0) {
						// Update shut off days in an hour so the days don't get out of sync
						window.setTimeout(
							function () {
								updateShutOffDate(shutOffDate);
							},
							1000 * 60 * 60
						);
					}
				}

				function updateAppNotice(type, shown, message) {
					const updateBanner = registeredMethods.get('updateBanner');
					if (!shown) {
						scope.appNotices.delete(type);
					} else {
						scope.appNotices.set(type, {
							message: message,
						});
					}

					seedcodeCalendar.init('appNotices', scope.appNotices);

					if (updateBanner) {
						updateBanner(scope.appNotices);
					}
					scope.appNotice = scope.appNotices.size > 0;
				}
			},
			template:
				'<svelte-component component="banner" props="props"></svelte-component>',
		};
	}
})();
